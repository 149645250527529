#eNshDmKof363_to {
    animation: eNshDmKof363_to__to 1000ms linear 1 normal forwards
}

@keyframes eNshDmKof363_to__to {
    0% {
        transform: translate(1260.33px, 497.257px)
    }
    1% {
        transform: translate(1250.169703px, 483.968894px)
    }
    76% {
        transform: translate(1257.20772px, 499.222931px)
    }
    100% {
        transform: translate(1260.33px, 497.257px)
    }
}

#eNshDmKof3618_to {
    animation: eNshDmKof3618_to__to 1000ms linear 1 normal forwards
}

@keyframes eNshDmKof3618_to__to {
    0% {
        transform: translate(1268.81px, 375.303px)
    }
    1% {
        transform: translate(1275.31288px, 362.222763px)
    }
    76% {
        transform: translate(1262.529227px, 378.541923px)
    }
    100% {
        transform: translate(1268.81px, 375.303px)
    }
}

#eNshDmKof3633_to {
    animation: eNshDmKof3633_to__to 1000ms linear 1 normal forwards
}

@keyframes eNshDmKof3633_to__to {
    0% {
        transform: translate(1372px, 422.5px)
    }
    1% {
        transform: translate(1340.915658px, 422.499987px)
    }
    93% {
        transform: translate(1372px, 422.5px)
    }
    100% {
        transform: translate(1372px, 422.5px)
    }
}

#eNshDmKof3633 {
    animation: eNshDmKof3633_c_o 1000ms linear 1 normal forwards
}

@keyframes eNshDmKof3633_c_o {
    0% {
        opacity: 1
    }
    1% {
        opacity: 0.3
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}
