.menu, .menu .btn .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.menu {
    width: 5em;
    height: 5em;
}
.menu .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -10;
    -webkit-transition: opacity 1s, z-index 0.3s, -webkit-transform 1s;
    transition: opacity 1s, z-index 0.3s, -webkit-transform 1s;
    transition: opacity 1s, z-index 0.3s, transform 1s;
    transition: opacity 1s, z-index 0.3s, transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    display: flex;
    justify-content: flex-start;
}
.menu .btn .fa {
    width: 100%;
    color: #0077c8;
    font-size: 0.75em;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.text_ua {
    font-size: .75em;
    padding: 0 10px;
    text-transform: uppercase;
    width: max-content;
}
.text_en {
    font-size: .85em;
    padding: 0 10px;
    color: black;
    width: max-content;
    margin-left: auto;
}
.text-wrapper {
    cursor: pointer;
    transition: .5s;
    width: max-content;
    border-radius: 40px;
}
.menu .btn:hover .fa {
    color: #e4002b;
}
.menu .rotater {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.menu.active .btn-icon {
    opacity: 1;
    font-weight: bold;
}

.rotater:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.rotater:nth-child(1) .text_en {
    margin: 0;
}

.menu.active .rotater:nth-child(1) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-45deg);
    transform: translateY(-18em) rotate(-45deg);
}

.rotater:nth-child(2) {
    -webkit-transform: rotate(63deg);
    transform: rotate(63deg);
}

.rotater:nth-child(2) .text_en {
    margin: 0;
}

.menu.active .rotater:nth-child(2) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-63deg);
    transform: translateY(-18em) rotate(-63deg);
}

.rotater:nth-child(3) {
    -webkit-transform: rotate(81deg);
    transform: rotate(81deg);
}
.rotater:nth-child(3) .text_en {
    margin: 0;
}

.menu.active .rotater:nth-child(3) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-81deg);
    transform: translateY(-18em) rotate(-81deg);
}

.rotater:nth-child(4) {
    -webkit-transform: rotate(99deg);
    transform: rotate(99deg);
}

.rotater:nth-child(4) .text_en {
    margin: 0;
}
.menu.active .rotater:nth-child(4) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-99deg);
    transform: translateY(-18em) rotate(-99deg);
}

.rotater:nth-child(5) {
    -webkit-transform: rotate(117deg);
    transform: rotate(117deg);
}
.rotater:nth-child(5) .text_en {
    margin: 0;
}

.menu.active .rotater:nth-child(5) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-117deg);
    transform: translateY(-18em) rotate(-117deg);
}

.rotater:nth-child(6) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.rotater:nth-child(6) .text_en {
    margin: 0;
}
.menu.active .rotater:nth-child(6) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(-135deg);
    transform: translateY(-18em) rotate(-135deg);
}

.rotater:nth-child(7) {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.rotater:nth-child(7) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(7) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(135deg);
    transform: translateY(-18em) rotate(135deg);
}

.rotater:nth-child(8) {
    -webkit-transform: rotate(243deg);
    transform: rotate(243deg);
}

.rotater:nth-child(8) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(8) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(117deg);
    transform: translateY(-18em) rotate(117deg);
}

.rotater:nth-child(9) {
    -webkit-transform: rotate(261deg);
    transform: rotate(261deg);
}

.rotater:nth-child(9) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(9) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(99deg);
    transform: translateY(-18em) rotate(99deg);
}

.rotater:nth-child(10) {
    -webkit-transform: rotate(279deg);
    transform: rotate(279deg);
}

.rotater:nth-child(10) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(10) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(81deg);
    transform: translateY(-18em) rotate(81deg);
}

.rotater:nth-child(11) {
    -webkit-transform: rotate(297deg);
    transform: rotate(297deg);
}

.rotater:nth-child(11) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(11) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(63deg);
    transform: translateY(-18em) rotate(63deg);
}

.rotater:nth-child(12) {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
}

.rotater:nth-child(12) .text-wrapper {
    float: right;
}

.menu.active .rotater:nth-child(12) .btn-icon {
    -webkit-transform: translateY(-18em) rotate(45deg);
    transform: translateY(-18em) rotate(45deg);
    display: flex;
    justify-content: flex-end;
}

.circle-piece {
    transition: 1s;
}
