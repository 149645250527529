* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    scroll-behavior: smooth;
}

.App {
    padding-top: 47px;
    height: 100vh;
    overflow: hidden;
}

.PageManager__Container {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
}

.PageManager__ContentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: stretch;
}

.PageManager__Container--Desktop {
    grid-template-columns: auto 1fr;
}

.PageManager__Container--Mobile {
    grid-template-columns: 1fr;
}
