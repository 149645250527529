#epj9mw04whrg3_to {
    animation: epj9mw04whrg3_to__to 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg3_to__to {
    0% {
        transform: translate(503.169021px, 476.709531px)
    }
    0.0002% {
        transform: translate(191.059021px, -610.800934px)
    }
    70% {
        transform: translate(503.167877px, 482.677414px)
    }
    80% {
        transform: translate(503.167877px, 462.583532px)
    }
    100% {
        transform: translate(503.169021px, 476.710000px)
    }
}

#epj9mw04whrg3 {
    animation: epj9mw04whrg3_c_o 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg3_c_o {
    0% {
        opacity: 0
    }
    0.0001% {
        opacity: 0
    }
    10% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

#epj9mw04whrg8_to {
    animation: epj9mw04whrg8_to__to 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg8_to__to {
    0% {
        transform: translate(1029.214034px, 474.197902px)
    }
    0.0002% {
        transform: translate(191.059021px, -610.800934px)
    }
    70% {
        transform: translate(1029.210785px, 485.641678px)
    }
    80% {
        transform: translate(1029.210785px, 454.127874px)
    }
    100% {
        transform: translate(1029.210785px, 474.197671px)
    }
}

#epj9mw04whrg8 {
    animation: epj9mw04whrg8_c_o 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg8_c_o {
    0% {
        opacity: 0
    }
    0.0001% {
        opacity: 0
    }
    10% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

#epj9mw04whrg13_to {
    animation: epj9mw04whrg13_to__to 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg13_to__to {
    0% {
        transform: translate(788.774797px, 448.657231px)
    }
    0.0002% {
        transform: translate(191.059021px, -610.800934px)
    }
    70% {
        transform: translate(788.771545px, 480.166183px)
    }
    80% {
        transform: translate(788.771545px, 416.996679px)
    }
    100% {
        transform: translate(788.771545px, 448.652379px)
    }
}

#epj9mw04whrg13 {
    animation: epj9mw04whrg13_c_o 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg13_c_o {
    0% {
        opacity: 0
    }
    0.0001% {
        opacity: 0
    }
    10% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

#epj9mw04whrg20_ts {
    animation: epj9mw04whrg20_ts__ts 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg20_ts__ts {
    0% {
        transform: translate(190.278780px, 189.526540px) scale(1, 1)
    }
    0.0002% {
        transform: translate(190.278780px, 189.526540px) scale(0, 0)
    }
    10% {
        transform: translate(190.278780px, 189.526540px) scale(0, 0)
    }
    100% {
        transform: translate(190.278780px, 189.526540px) scale(1, 1)
    }
}

#epj9mw04whrg20 {
    animation: epj9mw04whrg20_c_o 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg20_c_o {
    0% {
        opacity: 0
    }
    0.0001% {
        opacity: 0
    }
    10% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

#epj9mw04whrg21_ts {
    animation: epj9mw04whrg21_ts__ts 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg21_ts__ts {
    0% {
        transform: translate(190.280032px, 177.290612px) scale(1, 1)
    }
    0.0002% {
        transform: translate(190.280032px, 177.290612px) scale(0, 0)
    }
    10% {
        transform: translate(190.280032px, 177.290612px) scale(0, 0)
    }
    100% {
        transform: translate(190.280032px, 177.290612px) scale(1, 1)
    }
}

#epj9mw04whrg21 {
    animation: epj9mw04whrg21_c_o 1000ms linear 1 normal forwards
}

@keyframes epj9mw04whrg21_c_o {
    0% {
        opacity: 0
    }
    0.0001% {
        opacity: 0
    }
    10% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}
